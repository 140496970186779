/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { createRef } from 'react';
import { connect } from 'react-redux';

import CSS from 'Util/CSS';

import { getCurrentStoreBrandsConfig } from '../../util/Brands';
import { BrandDetailsContainer } from '../BrandDetails/BrandDetails.container';
import BrandLogoComponent from './BrandLogo.component';

/** @namespace Scandiweb/AmastyShopbyBrand/Component/BrandLogo/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    brands: state.ConfigReducer.brandsConfig.ambrandlist,
    brandsConfig: state.ConfigReducer.brandsConfig.availableStores,
    storeCode: state.ConfigReducer.code
});

/** @namespace Scandiweb/AmastyShopbyBrand/Component/BrandLogo/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/**
 * @namespace Scandiweb/AmastyShopbyBrand/Component/BrandLogo/Container
 */
export class BrandLogoContainer extends BrandDetailsContainer {
    logoRef = createRef();

    componentDidMount() {
        const {
            brandsConfig = [],
            storeCode
        } = this.props;

        const {
            amshopby_brand_listing_brand_logo_width,
            amshopby_brand_listing_brand_logo_height
        } = getCurrentStoreBrandsConfig(brandsConfig, storeCode);

        CSS.setVariable(this.logoRef, 'brand-logo-listing-height', `${amshopby_brand_listing_brand_logo_height}px`);
        CSS.setVariable(this.logoRef, 'brand-logo-listing-width', `${amshopby_brand_listing_brand_logo_width}px`);
    }

    containerProps() {
        const { logoRef } = this;
        return {
            ...super.containerProps(),
            logoRef
        };
    }

    render() {
        return (
            <BrandLogoComponent
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandLogoContainer);
