/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import getStore from 'Util/Store';

import { getProductClickData, getProductImpressionsData } from '../data/list';
import { GTM_EVENT_KEY_IMPRESSIONS, GTM_EVENT_KEY_PRODUCT_CLICK } from '../util/events';
import { pushToDataLayer } from '../util/push';
import { debounceCallback } from '../util/wait';

/** @namespace Scandiweb/Gtm/Event/List/fireImpressionEvent */
export const fireImpressionEvent = (product, position, forcedList) => {
    const {
        events: {
            gtm_impressions_plp
        } = {}
    } = getStore().getState().ConfigReducer.gtm;

    // TODO: join multiple impressions
    if (gtm_impressions_plp) {
        const execute = async (product, position, forcedList) => {
            pushToDataLayer({
                event: GTM_EVENT_KEY_IMPRESSIONS,
                ...await getProductImpressionsData(product, position, forcedList)
            });
        };

        execute(product, position, forcedList);
    }
};

/** @namespace Scandiweb/Gtm/Event/List/fireProductClickEvent */
export const fireProductClickEvent = (product, position, forcedList) => {
    const {
        events: {
            gtm_product_click
        } = {}
    } = getStore().getState().ConfigReducer.gtm;

    if (gtm_product_click) {
        const execute = debounceCallback(async (product, position, forcedList) => {
            pushToDataLayer({
                event: GTM_EVENT_KEY_PRODUCT_CLICK,
                ...await getProductClickData(product, position, forcedList)
            });
        });

        execute(product, position, forcedList);
    }
};
