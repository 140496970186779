/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import BrandLogo from '../../component/BrandLogo';
import { getCurrentStoreBrandsConfig } from '../../util/Brands';

const addBrandsConfigToMapStateToProps = (args, callback) => {
    const [state] = args;
    return {
        ...callback(...args),
        brandsConfig: state.ConfigReducer.brandsConfig,
        storeCode: state.ConfigReducer.code
    };
};

const addConfigToContainerProps = (args, callback, instance) => {
    const { brandsConfig, storeCode } = instance.props;

    if (brandsConfig && storeCode) {
        return {
            ...callback(...args),
            brandsConfig,
            storeCode
        };
    }

    if (brandsConfig) {
        return {
            ...callback(...args),
            brandsConfig
        };
    }

    if (storeCode) {
        return {
            ...callback(...args),
            storeCode
        };
    }

    return {
        ...callback(...args)
    };
};

const addBrandLogoToAddSections = (args, callback, instance) => {
    const {
        product: {
            attributes = {}
        } = {},
        brandsConfig: {
            availableStores = []
        } = {},
        storeCode
    } = instance.props;

    if (!availableStores.length) {
        return callback(args);
    }

    const {
        amshopby_brand_product_listing_settings_show_on_listing: isLogoOnCard,
        amshopby_brand_general_attribute_code: brandAttribute
    } = getCurrentStoreBrandsConfig(availableStores, storeCode);

    const {
        [brandAttribute]: {
            attribute_options = {}
        } = {}
    } = attributes;

    const brandData = Object.values(attribute_options);

    if (!isLogoOnCard || !brandData.length) {
        return callback(args);
    }

    const brandId = Number(brandData[0].value);

    return <BrandLogo brandId={ brandId } />;
};

export default {
    'Component/ProductCard/Container/mapStateToProps': {
        function: addBrandsConfigToMapStateToProps
    },
    'Component/ProductCard/Container': {
        'member-function': {
            containerProps: addConfigToContainerProps
        }
    },
    'Component/ProductCard/Component': {
        'member-function': {
            renderBrand: addBrandLogoToAddSections
        }
    }
};
