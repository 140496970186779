/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';
import { connect } from 'react-redux';

import CSS from 'Util/CSS';

import { getCurrentStoreBrandsConfig } from '../../util/Brands';
import BrandDetails from './BrandDetails.component';

/** @namespace Scandiweb/AmastyShopbyBrand/Component/BrandDetails/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    brands: state.ConfigReducer.brandsConfig.ambrandlist,
    brandsConfig: state.ConfigReducer.brandsConfig.availableStores,
    storeCode: state.ConfigReducer.code
});

/** @namespace Scandiweb/AmastyShopbyBrand/Component/BrandDetails/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Scandiweb/AmastyShopbyBrand/Component/BrandDetails/Container */
export class BrandDetailsContainer extends PureComponent {
    static propTypes = {
        brandId: PropTypes.number.isRequired,
        brands: PropTypes.shape({
            find: PropTypes.func
        }).isRequired,
        brandsConfig: PropTypes.object.isRequired,
        storeCode: PropTypes.string.isRequired
    };

    wrapperRef = createRef();

    containerFunctions = {};

    componentDidMount() {
        // const {
        //     brandsConfig = [],
        //     storeCode
        // } = this.props;

        // const {
        //     amshopby_brand_product_page_height,
        //     amshopby_brand_product_page_width
        // } = getCurrentStoreBrandsConfig(brandsConfig, storeCode);

        // CSS.setVariable(this.wrapperRef, 'brand-logo-height', `${amshopby_brand_product_page_height}px`);
        // CSS.setVariable(this.wrapperRef, 'brand-logo-width', `${amshopby_brand_product_page_width}px`);
    }

    containerProps() {
        const {
            brands: {
                items: allBrandsConfig = []
            } = {},
            brandId,
            brandsConfig = [],
            storeCode
        } = this.props;

        const { wrapperRef } = this;

        const currentStoreBrandsConfig = brandsConfig.length
            ? getCurrentStoreBrandsConfig(brandsConfig, storeCode) : {};

        // ↓↓↓ Find brand by id to get image, description and other data
        const brand = allBrandsConfig.length ? allBrandsConfig.find(
            ({ brandId: itemBrandId }) => itemBrandId === brandId
        ) : [];

        return {
            ...brand,
            currentStoreBrandsConfig,
            wrapperRef
        };
    }

    render() {
        return (
            <BrandDetails
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandDetailsContainer);
