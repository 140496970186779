/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* eslint-disable react/prop-types */

import { PureComponent } from 'react';

import Image from 'Component/Image';
import Link from 'Component/Link';

import './BrandLogo.style.scss';

/**
 *  @namespace Scandiweb/AmastyShopbyBrand/Component/BrandLogo/Component
 */
export class BrandLogoComponent extends PureComponent {
    render() {
        const {
            image,
            url,
            logoRef
        } = this.props;

        if (!image) {
            return null;
        }

        return (
            <div block="BrandLogo" ref={ logoRef }>
                <Link block="BrandLogo" elem="Link" to={ url }>
                    <Image
                      mix={ { block: 'BrandLogo', elem: 'Image' } }
                      src={ `/${ image }` }
                      ratio="custom"
                      objectFit="cover"
                    />
                </Link>
            </div>
        );
    }
}

export default BrandLogoComponent;
