/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import Html from 'Component/Html';
import Image from 'Component/Image';
import Link from 'Component/Link';
import TextPlaceholder from 'Component/TextPlaceholder';
import { CategoryDetailsComponent as SourceCategoryDetails } from 'SourceComponent/CategoryDetails/CategoryDetails.component';

import './BrandDetails.style';

/** @namespace Scandiweb/AmastyShopbyBrand/Component/BrandDetails/Component */
export class CategoryDetailsComponent extends SourceCategoryDetails {
    renderBrandLabel() {
        const {
            label,
            currentStoreBrandsConfig: {
                amshopby_brand_product_page_display_title
            } = {}
        } = this.props;

        if (!amshopby_brand_product_page_display_title) {
            return null;
        }

        return (
            <h1 block="CategoryDetails" elem="Heading">
                <TextPlaceholder content={ label } />
            </h1>
        );
    }

    renderBrandLogo() {
        const {
            image,
            url,
            currentStoreBrandsConfig: {
                amshopby_brand_product_page_display_brand_image
            } = {}
        } = this.props;

        if (!image || !amshopby_brand_product_page_display_brand_image) {
            return null;
        }

        return (
            <Link block="BrandDetails" elem="Image" to={ url }>
                <Image
                  mix={ { block: 'CategoryDetails', elem: 'Picture' } }
                  src={ `/${ image }` }
                  ratio="custom"
                  objectFit="cover"
                />
            </Link>
        );
    }

    renderShortDescription() {
        const {
            description,
            currentStoreBrandsConfig: {
                amshopby_brand_product_page_display_description
            } = {}
        } = this.props;

        if (!description || !amshopby_brand_product_page_display_description) {
            return null;
        }

        return (
            <div block="BrandDetails" elem="ShortDescription">
                <Html content={ description } />
            </div>
        );
    }

    render() {
        const { wrapperRef } = this.props;
        return (
            <article block="CategoryDetails" ref={ wrapperRef }>
                <div block="CategoryDetails" elem="Description">
                    { this.renderBrandLabel() }
                    { this.renderBrandLogo() }
                    { this.renderShortDescription() }
                </div>
            </article>
        );
    }
}

export default CategoryDetailsComponent;
