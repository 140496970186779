/* eslint-disable simple-import-sort/imports */

import TextPlaceholder from 'Component/TextPlaceholder';
import {
    ProductCardComponent as SourceProductCardComponent,
} from 'SourceComponent/ProductCard/ProductCard.component';
import Link from 'Component/Link';

import './ProductCard.override.style';

/** @namespace Scandipwa/Component/ProductCard/Component */
export class ProductCardComponent extends SourceProductCardComponent {
    /*
     * Overridden to update the add to cart button when it requires configuration
     */
    renderAddToCart() {
        const {
            layout,
            showSelectOptionsNotification,
            inStock,
            device: { isMobile } = {},
        } = this.props;

        const requiresConfiguration = this.requiresConfiguration();

        if (inStock && requiresConfiguration) {
            return (
                <button
                  block="AddToCart"
                  mods={ { layout, isHollow: true } }
                  mix={ { block: 'Button', mods: { isHollow: true } } }
                  onClick={ showSelectOptionsNotification }
                >
                    { __('Select options') }
                </button>
            );
        }

        if (!inStock && !isMobile) {
            return null;
        }

        return this.renderAddToCartButton(layout);
    }

    /*
     * Overridden to remove product actions based on design
     * Display for mobile
     * Removed configurable options
     */
    renderVisibleOnHover() {
        return (
            <div block="ProductCard" elem="Footer">
                { this.renderAddToCart() }
            </div>
        );
    }

    renderNumberOfOptions() {
        const { product: { variants } } = this.props;

        if (variants && variants.length > 0) {
            return (
                <div block="ProductCard" elem="NumOfOption">
                    <p block="ProductCard" elem="NumOfOptionValue">
                        { __('%s options available', variants.length) }
                    </p>
                </div>
            );
        }

        return null;
    }

    renderCardLinkWrapper(children, mix = {}) {
        const { linkTo = '', product: { url } } = this.props;

        if (!url) {
            return (
                <div
                  block="ProductCard"
                  elem="Link"
                >
                    { children }
                </div>
            );
        }

        return (
            <Link
              block="ProductCard"
              elem="Link"
              to={ linkTo }
              onClick={ this.handleLinkClick }
            //   showLoader
              onClickBeforeLoader={ this.handleLinkClick }
              mix={ mix }
            >
              { children }
            </Link>
        );
    }

    /*
     * Overridden to add stock icon and stock, and remove review block
     */
    renderCardContent() {
        const { renderContent, product: { name } } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            this.renderCardLinkWrapper((
                <div block="ProductCard" elem="LinkInnerWrapper" mods={ { loaded: !!name } }>
                    <div block="ProductCard" elem="FigureReview">
                        <figure block="ProductCard" elem="Figure">
                            { this.renderPicture() }
                            { this.renderNumberOfOptions() }
                        </figure>
                    </div>
                    <div block="ProductCard" elem="Content">
                        { this.renderBrand() }
                        { this.renderName(false) }
                        { this.renderPrice() }
                    </div>
                    <div block="ProductCard" elem="VisibleOnHover">
                        { this.renderVisibleOnHover() }
                    </div>
                </div>
            ))
        );
    }

    /**
     * Overriden to render product title names as h3 tags instead of h1 tags
     *
     * @param header If header outputs as H3
     * @param dynamic Name type (false - shows parent product only)
     * @returns {JSX.Element}
     */
    renderName(header = true, dynamic = false) {
        if (header) {
            return super.renderName(header, dynamic);
        }

        const { product: { name }, productName } = this.props;
        const nameToRender = dynamic ? productName : name;

        return (
            <h3 block={ this.className } elem="Name" itemProp="name">
                <TextPlaceholder content={ nameToRender } length="medium" />
            </h3>
        );
    }
}

export default ProductCardComponent;
